import React from 'react'
import { Box, CardMedia, Container, Grid, Typography, Button, colors } from '@mui/material'


import prgrm1 from "../../assets/images/home/prgrm1.png";
import prgrmimg from "../../assets/images/driving-lesson/safe2drive.png"


import { Timeline, TimelineItem, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent } from '@mui/lab';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import Register from '../Register/Register'
import { useState } from 'react'




const styles = {

    buttonWhite: {
        background: '#fff',
        color: '#5B5B5B',
        textTransform: 'capitalize',
        padding: '10px 20px',
        '&:hover': {
            background: 'var(--primary) ',
            color: '#fff',
        }
    },

    timelineItems: {

        marginBottom: '10px',

        '&.MuiTimelineItem-root:before': {
            flex: 0,
            padding: 0,
        },
    },

    timelineNumbers: {
        background: 'var(--green)',
        color: '#fff !important',
        padding: '0px 18px',
        fontSize: '26px !important',
        fontWeight: '600 !important',
        borderRadius: '50%',
        marginBottom: '10px',
        width: '42px',
        height: '42px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: '1 !important',
    },

    safe2Drive: {
        display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: '#F1F1F1', padding: '20px', width: '60%',
        '@media (max-width: 900px)': {
            width: '100%',
        }
    }




}

const programsData = [
    {
        prmImage: prgrmimg,
        prmTittle: "Online Driver’s Education Course",
        prmTittle1: "(Self Paced)",
        prmTittle2:
            "30 Hours",
    },

];



const DloGettingReady = () => {

    const [registerStatus, setRegisterStatus] = useState(false)

    return (
        <>

            <Box sx={{ py: "50px", }}>
                <Container maxWidth='lg'>

                    <Box sx={{ padding: "0px 0px 60px", }}>

                        <Grid sx={{ border: '1px solid var(--primary)', padding: '20px 40px', borderRadius: '10px', background: 'var(--lightblue)' }}>
                            <Typography component='f14'>1 ACT Driving School has partnered with <a target='_blank' href="https://www.safe2drive.com/student/en/misc/session_timeout.aspx">Safe2Drive</a> to provide an online Driver's course that meets all of the <a href="/joshua's-law-info" target='blank'>Joshua's Law</a> </Typography>
                            <br />
                            <Typography variant='h6' sx={{ mt: 0.5 }}>Safe2Drive's comprehensive 30-Hour Online Driver's Education course is:</Typography>

                            <Typography sx={{ display: 'flex', alignItems: 'baseline', p: '10px 0px 0px' }}><FiberManualRecordIcon sx={{ fontSize: '10px', color: 'var(--primary)' }} />&nbsp; Approved by the Georgia Department of Driver Services (DDS).</Typography>
                            <Typography sx={{ display: 'flex', alignItems: 'baseline', p: '10px 0px 0px' }}><FiberManualRecordIcon sx={{ fontSize: '10px', color: 'var(--primary)' }} />&nbsp; 100% online - Take your course anytime, anywhere.</Typography>
                            <Typography sx={{ display: 'flex', alignItems: 'baseline', p: '10px 0px 0px' }}><FiberManualRecordIcon sx={{ fontSize: '10px', color: 'var(--primary)' }} />&nbsp; Fun - Packed with games, interactive animations, videos, and more!</Typography>
                            <Typography sx={{ display: 'flex', alignItems: 'baseline', p: '10px 0px 0px' }}><FiberManualRecordIcon sx={{ fontSize: '10px', color: 'var(--primary)' }} />&nbsp; Available 24/7 - Log in and out as much as you want. We always save your place!</Typography>
                            <Typography sx={{ display: 'flex', alignItems: 'baseline', p: '10px 0px 0px' }}><FiberManualRecordIcon sx={{ fontSize: '10px', color: 'var(--primary)' }} />&nbsp; Convenient - We will process your completion to the Georgia DDS and Email you your certificate!</Typography>

                        </Grid>

                    </Box>

                    <Grid container spacing={2}>



                        <Grid item md={8}>

                            {/* <Grid sx={styles.safe2Drive}>
                    <Typography component='f14'>1 ACT DUI & Defensive Driving School has partnered with</Typography>
                    <Typography component='span1' sx={{color:'var(--primary) !important', fontWeight:'700 !important'}}>safe2drive</Typography>
                </Grid> */}

                            <Grid sx={{ mt: '30px', }}>
                                <Typography variant='span1'>Getting started is easy!</Typography><br />
                                <Typography component='f14'>Simply follow these steps</Typography>
                            </Grid>

                            <Grid sx={{ mt: '30px', }}>

                                <Timeline>

                                    <TimelineItem sx={styles.timelineItems}>
                                        <TimelineSeparator className='TimelineLine'>
                                            <Typography sx={styles.timelineNumbers}>1</Typography>
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent sx={{ pb: '30px' }}>
                                            <Typography variant='h2' sx={{ pb: '10px' }}>Register</Typography>
                                            <Typography component='f14'>Create a username and password on Safe2Drive's website. You can log in and out as needed.</Typography>
                                        </TimelineContent>
                                    </TimelineItem>

                                    <TimelineItem sx={styles.timelineItems}>
                                        <TimelineSeparator className='TimelineLine'>
                                            <Typography sx={styles.timelineNumbers}>2</Typography>
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent sx={{ pb: '30px' }}>
                                            <Typography variant='h2' sx={{ pb: '10px' }}>Complete the Course</Typography>
                                            <Typography component='f14'>The 30-hour course is split up into timed lessons that you can complete at your own pace. There is a multiple-choice quiz at the end of each lesson. With passing the quiz, you can move on to the next lesson.</Typography>
                                        </TimelineContent>
                                    </TimelineItem>

                                    <TimelineItem sx={styles.timelineItems}>
                                        <TimelineSeparator className='TimelineLine'>
                                            <Typography sx={styles.timelineNumbers}>3</Typography>
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent sx={{ pb: '30px' }}>
                                            <Typography variant='h2' sx={{ pb: '10px' }}>Pass the Exam</Typography>
                                            <Typography component='f14'>At the end of the course, you’ll have to attend a final exam with 30 multiple-choice questions. You need a minimum score of 70% to pass the exam. You can retake the exam for free as many times as needed.</Typography>
                                        </TimelineContent>
                                    </TimelineItem>

                                    <TimelineItem sx={styles.timelineItems}>
                                        <TimelineSeparator className='TimelineLine'>
                                            <Typography sx={styles.timelineNumbers}>4</Typography>
                                        </TimelineSeparator>
                                        <TimelineContent sx={{ pb: '30px' }}>
                                            <Typography variant='h2' sx={{ pb: '10px' }}>Get Your Certificate</Typography>
                                            <Typography component='f14'>After you pass the final exam, Safe2Drive will process your course completion with the Georgia DDS and email you your certificate.</Typography>
                                        </TimelineContent>
                                    </TimelineItem>

                                </Timeline>

                            </Grid>

                        </Grid>
                        <Grid item md={4}>

                            {programsData.map((program, index) => (
                                <Grid className="startTestingBox" item key={index} xs={10} sm={10} md={10} sx={{ boxShadow: '1px 1px 6px 2px #0000001f', border: '1px solid #0000001f', marginLeft: 'auto', marginTop: '20px', marginBottom: '60px', }}>
                                    <Grid className="program_box_main startTestingBoxMain" sx={{}}>

                                        <Grid className="prgrm_con">

                                            <Grid className="prgrm_txt">

                                                <Grid className="" style={{ padding: "30px 20px 0px" }}>

                                                    <CardMedia component="img" alt="program Image" src={program.prmImage} sx={{ width: '152px', height: '20px', mb: '14px' }} />

                                                    <Typography component='f20w600' sx={{ margin: "10px 0px 8px", }} >
                                                        {program.prmTittle}
                                                    </Typography>
                                                    <Typography sx={{ margin: "0px 0px 10px", }} >
                                                        {program.prmTittle1}
                                                    </Typography>

                                                    <Typography sx={{ color: 'var(--primary) !important' }}>
                                                        {program.prmTittle2}
                                                    </Typography>

                                                </Grid>

                                                <Grid className="prgrm_but" style={{ marginTop: "30px" }}>
                                                    <a target='_blank' href='https://www.safe2drive.com/student/en/registration/register_6.aspx' className="btns btn_1 hvr-bounce-to-right"> Register </a>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}

                        </Grid>
                    </Grid>

                    <Box sx={{ border: '1px solid var(--primary)', padding: '20px 40px', borderRadius: '10px', background: 'var(--lightblue)', textAlign: "center", }}>
                        <Typography component="span1">Safe2Drive’s Award-Winning Customer Care Team is here to answer any questions you might have. Just call <a href="call:(800) 763-1297">(800) 763-1297</a> or email <a href="mailto:support@safe2drive.com.">support@safe2drive.com.</a>
                            &nbsp;
                        </Typography>
                        <br />
                        {/* <Button variant="outlined" className='greenButAni' 
                        
                        >
                          <a target='_blank' href='https://www.safe2drive.com/student/en/registration/register_6.aspx'> Sign up today</a> 
                        </Button> */}
                        <Register openStatus={registerStatus} setRegisterStatus={setRegisterStatus} />
                    </Box>

                </Container>
            </Box>

        </>
    )
}

export default DloGettingReady
