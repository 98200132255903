
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { IoMdClose } from "react-icons/io";
import {
  Button,
  FormHelperText,
  Grid,
  Link,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack, Typography, DialogTitle, Dialog, DialogContent, IconButton, TextField, Divider,
} from '@mui/material';

import * as Yup from 'yup';
import { Formik } from 'formik';

import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import axios from 'axios';
import { base_Url } from '../BaseUrl/ApiUrl';
import NewUserRegister from './NewUserRegister';
import ExistingUserRegister from '../ExistingUser/ExistingUserRegister';
import ForgotPassword from '../../components/Header/ForgotPassword';

import { Toaster, toast } from "sonner";



const success = (data) => {
  toast.success(data, {
    position: 'top-center',
    style: { backgroundColor: 'green', color: 'white' },

    cancel: {
      label: "close",
      onClick: () => console.log('Cancel!'),
    },
  });
}

const failure = (data) => {
  toast.error(data, {
    position: 'top-center',
    style: { border: '1px solid red' },

    cancel: {
      label: "x",
      // style: {
      //     backgroundColor: '#a8261d', 
      //     color: '#ffffff', 
      //     border: 'none', 
      //     padding: '2px 8px',
      //     borderRadius: '4px', 
      // },
      onClick: () => console.log('Cancel!'),
    },

  });
}


const Register = ({ setRegisterStatus, openStatus, data, finalData, classmode, btw }) => {


  console.log('btw >>>', btw)
  console.log(finalData?.packages?.map(item => item?.packageid))




  const [newFinalData, setNewFinalData] = useState({})

  const fetchcheckPackageisExist = async (packageid) => {
    try {
      const response = await axios.post(`${base_Url}/checkPackageisExist`, {
        packageid: finalData?.packages?.map(item => item?.packageid)

      })
      if (response.data.message === 'success') {
        console.log('response', response?.data?.response[0])
        setNewFinalData(response?.data?.response)
      } else {
        setNewFinalData({})
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    // if (btw) {
    fetchcheckPackageisExist()
    // }

  }, [finalData])







  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const opensuccesstoast = (message) => {
    enqueueSnackbar(message, {
      variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'center' },
      autoHideDuration: 2000
    })
  }
  const openfailuretoast = (message) => {
    enqueueSnackbar(message, {
      variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' },
      autoHideDuration: 4000
    })
  }


  const [newUser, setNewUser] = useState(false)
  const [extUser, setExtUser] = useState(false)


  const handleNewUser = () => {
    setNewUser(true)
    setRegisterStatus(false)
  }


  console.log('finalData <<<<<#####3', finalData)
  const studentDetails = JSON.parse(localStorage.getItem('Student'))
  const userid = studentDetails?.customerid


  const [userUpdated, setUserUpdate] = useState(false)

  useEffect(() => {

  }, [userUpdated]);



  const [passwordOpen, setPasswordOpen] = useState(false)


  const [Email, setEmail] = useState(null)
  const handleForgot = async (email) => {

    console.log('email', email)
    setEmail(email)

    try {
      const response = await axios.post(`${base_Url}/forgotPassword`, {
        email: email,
        roleid: 2

      })
      if (response.data.status) {

        success(response.data.message)
        setPasswordOpen(true)
        setRegisterStatus(false)
      }
      else {
        failure(response.data.message)
      }
    } catch (error) {
      console.log('error', error)
    }

  }





  return (
    <>
      <Toaster />

      <ForgotPassword
        passwordOpen={passwordOpen}
        setPasswordOpen={setPasswordOpen}
        Email={Email}

      />

      {
        userid ?

          <ExistingUserRegister
            openStatus={openStatus}
            setRegisterStatus={setRegisterStatus}
            finalData={newFinalData}
          />

          :

          <Dialog
            fullWidth
            maxWidth='sm'
            onClose={() => setRegisterStatus(false)}
            open={openStatus}
            sx={{ m: 2 }}>


            <DialogTitle>
              <Stack direction='row' justifyContent='space-between'>
                <Typography variant='h5' sx={{ color: 'black !important' }}>
                  New User
                </Typography>
                <IconButton onClick={() => setRegisterStatus(false)} sx={{ color: 'red' }}>
                  <IoMdClose />
                </IconButton>

              </Stack>

            </DialogTitle>

            <DialogContent>

              <Grid item xs={12} display="flex" justifyContent="center">

                <Typography
                  sx={(theme) => ({
                    cursor: 'pointer',
                    margin: '20px 0',
                    textDecoration: 'underline',
                    display: 'inline-block', // Ensures hover effects apply correctly
                    fontSize: '20px', // Default font size
                    [theme.breakpoints.up('xs')]: {
                      fontSize: '20px !important', // Font size for small screens
                    },
                    [theme.breakpoints.up('sm')]: {
                      fontSize: '25px !important', // Font size for small screens
                    },
                    [theme.breakpoints.up('lg')]: {
                      fontSize: '25px !important', // Font size for large screens
                    },
                    '& a': {
                      color: 'inherit',
                      fontWeight: 'bold', // Emphasize the action
                      borderBottom: '2px solid transparent', // Add underline effect on hover
                      transition: 'color 0.3s ease, border-bottom 0.3s ease', // Smooth transitions
                    },
                    '& a:focus': {
                      outline: '2px solid #ffcc00', // Accessibility focus highlight
                    },
                  })}
                  onClick={() => handleNewUser()}
                >
                  <a>Click here to Purchase</a>
                </Typography>

              </Grid>

              <Grid item xs={12} justifyContent={'center'}>
                <Divider textAlign="center" sx={{ margin: '20px 0' }}>
                  <Typography variant="subtitle1" component="span">
                    Or
                  </Typography>
                </Divider>
                {/* <Typography sx={{ textAlign: 'center' }}>Or</Typography> */}
              </Grid>




              <Formik
                initialValues={{
                  email: '',
                  password: '',
                  submit: null
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .matches(
                      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
                      'Invalid email format'
                    )
                    .required('Email is required'),
                  password: Yup.string().max(255).required('Password is required')
                })}
                onSubmit={async (values,) => {
                  try {

                    // console.log('register form data', values);

                    const requestData = {

                      email: values.email,
                      password: values.password,
                      "packageid": finalData?.packages?.map(item => item?.packageid),
                      "comboid": finalData?.comboid


                    };

                    const response = await axios.post(
                      `${base_Url}/checkExistingUser`,
                      requestData
                    );


                    console.log(response)


                    if (response.data.status === true) {
                      opensuccesstoast(response.data.message)
                      console.log('data', response.data)
                      localStorage.setItem('Student', JSON.stringify(response?.data?.response[0]));



                      setUserUpdate(true)
                      setExtUser(true)
                      setRegisterStatus(false)

                    } else {
                      openfailuretoast(response.data.message)
                      localStorage.setItem('Student', JSON.stringify(response?.data?.response[0]));
                    }

                  } catch (error) {
                    console.log('post register api status', error);
                  }



                }}
              >



                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                  <form noValidate onSubmit={handleSubmit}>

                    <Grid container spacing={3}>

                      <Typography sx={{ color: 'black !important', margin: '20px 0 0 20px', fontSize: '17px !important', fontWeight: 'bold !important' }}>
                        Returning  User
                      </Typography>

                      <Grid item xs={12}>
                        <Stack spacing={1} direction={'column'} alignItems={'center'}>
                          <InputLabel htmlFor="email-login"></InputLabel>
                          <TextField
                            size='small'
                            label='Email'
                            id="email-login"
                            type="email"
                            value={values.email}
                            name="email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder="Enter email address"
                            sx={{ sm: { width: '60%' }, md: { width: '45%' } }}
                            error={Boolean(touched.email && errors.email)}
                          />
                          {touched.email && errors.email && (
                            <FormHelperText error id="standard-weight-helper-text-email-login" sx={{ color: 'red !important' }}>
                              {errors.email}
                            </FormHelperText>
                          )}

                        </Stack>
                      </Grid>
                      <Grid item xs={12} >
                        <Stack spacing={1} direction={'column'} alignItems={'center'} >

                          <TextField
                            // sx={{  width: '45%' }}
                            sx={{ sm: { width: '60%' }, md: { width: '45%' }, borderRadius: '30px !important' }}
                            size='small'
                            label='Password'
                            error={Boolean(touched.password && errors.password)}
                            id="-password-login"
                            type={showPassword ? 'text' : 'password'}
                            value={values.password}
                            name="password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                  color="secondary"
                                >
                                  {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                </IconButton>
                              </InputAdornment>
                            }
                            placeholder="Enter password"
                          />
                          {touched.password && errors.password && (
                            <FormHelperText error id="standard-weight-helper-text-password-login" sx={{ color: 'red !important' }}>
                              {errors.password}
                            </FormHelperText>
                          )}
                        </Stack>
                      </Grid>

                      {errors.submit && (
                        <Grid item xs={12}>
                          <FormHelperText error>{errors.submit}</FormHelperText>
                        </Grid>
                      )}

                      <Stack direction={'row'} justifyContent={'center'} sx={{ width: '100%', margin: '10px 0 0 0' }}>
                        <Typography
                          sx={(theme) => ({
                            cursor: 'pointer',
                            float: 'right',
                            fontSize: '10px', // Default font size for very small devices
                            [theme.breakpoints.only('xs')]: {
                              fontSize: '10px !important', // Font size for extra small devices
                            },
                            [theme.breakpoints.up('sm')]: {
                              fontSize: '7px !important', // Font size for small screens and up
                            },
                            [theme.breakpoints.up('lg')]: {
                              fontSize: '15px !important', // Font size for large screens and up
                            },
                          })}
                          onClick={() => handleForgot(values.email)}
                        >
                          Forgot password?
                        </Typography>
                      </Stack>

                      <Grid item xs={12} >

                        <Stack direction={'column'} alignItems={'center'}>
                          <Button sx={{
                            borderRadius: '30px',
                            backgroundColor: 'black !important',
                            width: '45%'
                          }}
                            disableElevation
                            disabled={isSubmitting}
                            size="samll"
                            type="submit"
                            variant="contained"

                          >
                            Login
                          </Button>
                        </Stack>
                      </Grid>



                    </Grid>
                  </form>
                )}
              </Formik>

            </DialogContent>





          </Dialog>


      }



      <NewUserRegister
        openStatus={newUser}
        setRegisterStatus={setNewUser}
        finalData={finalData}
      />

      <ExistingUserRegister
        openStatus={extUser}
        setRegisterStatus={setExtUser}
        finalData={finalData}
      />


    </>
  );
};

export default Register;