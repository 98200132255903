import { Box, Button, CardMedia, Container, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'

import drivingLesson from "../../assets/images/driving-lesson/deriving-lesson-bg.png"
import conex from "../../assets/images/driving-lesson/conex.png"

import Register from '../Register/Register'
import { useState } from 'react'

import dlRightVideo from "../../assets/videos/DUI Loc.mp4"
import axios from 'axios'
import { base_Url } from '../BaseUrl/ApiUrl'
import QuestionDrawer from './QuestionDrawer'
import imgg from "../../assets/new7locations.png"
import { useNavigate } from 'react-router-dom'

const styles = {
  bannerRight: {
    '@media (max-width: 900px)': {
      paddingTop: '30px',
    }
  },
  dlBanner: {
    flexGrow: 1, background: `url(${drivingLesson}) no-repeat top center`, backgroundSize: '100% 100%', padding: '150px 0px 80px',
    '@media (max-width: 900px)': {
      padding: '50px 0px 50px'
    }
  }
}

const DrrlBanner = () => {


  const [registerStatus, setRegisterStatus] = useState(false)


  const [roadTesting, setRoadTesting] = useState([])
  const fetchPackages = async () => {
    try {
      const response = await axios.post(`${base_Url}/getAllCourse`, {
        type: ''
      })
      if (response.data.message === "success") {
        console.log('response', response?.data?.response)

        const course5 = response?.data?.response[1]
        setRoadTesting(course5)

      }
    } catch (error) {
      console.log(error)
    }
  }



  const [branchLocations, setBranchLocation] = useState([])
  const fetchLocations = async () => {
    try {
      const response = await axios.post(`${base_Url}/getAllBranches`, {
        type: ''
      })
      if (response.data.status) {
        console.log('response bbbbbb', response?.data?.response)
        setBranchLocation(response?.data?.response)

      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchPackages()
    fetchLocations()
  }, [])
  console.log('roadTesting', roadTesting)


  console.log('branchLocations', branchLocations)




  const [finalData, setFinalData] = useState({})

  const fetchcheckPackageisExist = async (packageid) => {
    try {
      const response = await axios.post(`${base_Url}/checkPackageisExist`, {
        packageid: packageid
      })
      if (response.data.message === 'success') {
        console.log('response', response?.data?.response[0])
        setFinalData(response?.data?.response)
        setRegisterStatus(true)
      } else {
        setFinalData({})
      }
    } catch (error) {
      console.log(error)
    }
  }


  const navigate = useNavigate()


  console.log('finalData', finalData)

  const locations = [
    'East Cobb',
    'Lawrenceville',
    'Johns Creek',
    'Sandy Springs',
    'Alpharetta',
  ];


  const [questionOpen, setQuestionOpen] = useState(false)

  return (
    <>

      <Box sx={styles.dlBanner}>
        <Container>
          <Grid container spacing={2} sx={{ alignItems: 'center' }}>
            <Grid item md={7}>
              {/* <Typography
                variant='h5'
                component=""
                sx={{
                  fontWeight: "400 !important",
                  lineHeight: "1.5",
                  color: "#fff !important",
                }}
              >
                Second chances start here!
              </Typography> */}

              <Typography
                variant="h1"
                sx={{ color: "var(--yellow) !important", mt: '20px', }}
              >
                DUI / Risk reduction (Location)
              </Typography>

              <Grid sx={{ mt: "30px", display: 'flex', flexDirection: 'column', gap: '10px', }}>

                {/* <Grid sx={{ display: "flex", }}>
                  <img
                    src={conex}
                    style={{
                      width: "20px",
                      height: "20px",
                      margin: "6px 6px 0px 0px",
                    }}
                  />
                  &nbsp;
                    <Typography color="#fff !important" fontWeight="400 !important" >
                    Everyone makes mistakes. We're here to guide you through it.
                    </Typography>

                </Grid> */}
                <Grid sx={{ display: "flex", }}>
                  <img src={conex} style={{ width: "20px", height: "20px", margin: "6px 6px 0px 0px", }} /> &nbsp;
                  <Typography color="#fff !important" fontWeight="400 !important" >
                    {/* Take The Fast Lane To Responsible Driving */}
                    Let's call it a Learning curve, Not a Dead End!
                  </Typography>
                </Grid>
                <Grid sx={{ display: "flex", }}>
                  <img src={conex} style={{ width: "20px", height: "20px", margin: "6px 6px 0px 0px", }} /> &nbsp;
                  <Typography color="#fff !important" fontWeight="400 !important" >
                    {/* Comprehensive DUI/Risk Reduction Zoom Classes */}
                    Our Expert instructors help you Navigate it with Ease
                  </Typography>
                </Grid>
                {/* <Grid sx={{ display: "flex", }}>
                  <img src={conex} style={{ width: "20px", height: "20px", margin: "6px 6px 0px 0px", }} /> &nbsp;
                  <Typography color="#fff !important" fontWeight="400 !important" >
                    Get Back on the Road with Confidence
                  </Typography>
                </Grid> */}
              </Grid>
              {roadTesting?.courseDetails?.map((program, index) => (
                <>

                  <Button key={index} variant="outlined" className='greenButAni' onClick={() => setQuestionOpen(true)}>
                    REGISTER NOW
                  </Button>



                  <QuestionDrawer
                    open={questionOpen}
                    setOpen={setQuestionOpen}
                    fetchcheckPackageisExist={fetchcheckPackageisExist}
                    packageid={[program.packageid]}
                    navigateto = {()=>{
                      navigate(`/dui-risk-reduction-course-remote`,{
                          state : {number : 2}
                      })
                  }}
                    route={''}
                    coursename={' DUI / Risk Reduction (zoom)'}

                  />
                </>

              ))}
              <Register
                openStatus={registerStatus}
                setRegisterStatus={setRegisterStatus}
                finalData={finalData}
                classmode={'2'}
              />

            </Grid>




            <Grid item md={5}>
              <CardMedia className="" component="img" alt="DD Zoom" src={imgg} sx={styles.bannerRight} />
              {/* <CardMedia component="video" autoPlay loop muted src={dlRightVideo} sx={styles.bannerRight} /> */}
            </Grid>
          </Grid>
        </Container>
      </Box>

    </>
  )
}

export default DrrlBanner

