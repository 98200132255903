import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import { base_Url } from '../BaseUrl/ApiUrl';
import axios from 'axios';
import { IoIosLogOut } from "react-icons/io";
import { CiWallet } from "react-icons/ci";
import { Grid, Stack, Typography, Avatar, Box, Divider, Button, Card } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MainCard from '../../components/MainCard';
import user from '../../assets/user.jpg';
import { MdAdminPanelSettings } from "react-icons/md";
import { FaWallet } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import moment from 'moment';
import _ from 'lodash'
import { useNavigate } from 'react-router-dom';

const MyProfile = () => {


    const navigate = useNavigate()
    const student = JSON.parse(localStorage.getItem('Student'));
    const [userData, setUserData] = useState([]);
    const [selectedMenu, setSelectedMenu] = useState('Personal Information');

    const fetchUserData = async () => {
        try {
            const response = await axios.get(`${base_Url}getStudentDataById/${student?.customerid}`);
            if (response.data.status === true) {
                setUserData(response.data.response[0]);
            }
        } catch (error) {
            console.log('getAllClients api status', error);
        }
    };
    const [transaction, setTransaction] = useState([])

    const fetchTransaction = async () => {
        try {
            const response = await axios.post(`${base_Url}/studentPaymentInsights`, {
                "customerid": student?.customerid,

            });
            console.log('response', response)
            if (response.data.response) {
                setTransaction(response?.data?.response)
            }

        } catch (error) {
            console.log(`error in handleFetchData at initial - dashboard - `, error);
        }
    };


    useEffect(() => {
        fetchTransaction()
    }, [])

    useEffect(() => {
        fetchUserData();
    }, []);

    const handleMenuClick = (menu) => {
        setSelectedMenu(menu);
    };

    const handleDownload = async (url) => {
        try {
            const response = await fetch(url, { mode: 'cors' });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const blob = await response.blob();
            const blobUrl = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = 'document';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);
        } catch (error) {
            console.error('Error during file download:', error);
        }
    };

    function calculateAge(birthDate) {
        const referenceDate = new Date('2027-06-13');
        const birth = new Date(birthDate);
        let age = referenceDate.getFullYear() - birth.getFullYear();

        if (referenceDate < new Date(referenceDate.getFullYear(), birth.getMonth(), birth.getDate())) {
            age--;
        }

        return age;
    }


    // const handleLogout = () => {
    //     navigate('/')
    //     localStorage.removeItem('Student')

    // }



    const handleLogout = async () => {

        const paramData = {
            userid: student?.customerid,
            roleid: 2, // 1=>admin,2=>student,3=>Instructor,4=>parent,5-Employee
            config: 1 // 1 - web, 2- app
        }

        await axios.post(`${base_Url}removefcmtoken`, paramData).then((res) => {
            console.log(`fcm removed successfullly`);
            if (res.data.status == true) {
                localStorage.removeItem('Student')
                navigate('/')
            }

        })
            .catch((error) => {
                console.log(`error in reomveFcmToken - `, error);
            })

    };

    return (
        <div>
            <Header />
            <Box sx={{ paddingTop: '120px' }}>
                <Grid gap={2} justifyContent={'center'} container>
                    <Grid item xs={12} md={9}>

                        {/* code starts */}

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                                <MainCard sx={{ mt: 2, p: 2 }} content={false}>
                                    <Stack direction={'row'} spacing={3} alignItems={'center'} sx={{ cursor: 'pointer' }}>
                                        <Avatar sx={{ bgcolor: 'orange', width: 47, height: 47 }} src={user} />
                                        <Stack spacing={0}>
                                            <Typography variant='caption'>Hello,</Typography>
                                            <Typography>{userData?.customername}</Typography>
                                        </Stack>
                                    </Stack>
                                </MainCard>

                                <MainCard sx={{ mt: 2 }} content={false}>
                                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}
                                        sx={{ cursor: 'pointer', p: 1.2 }}
                                        onClick={() => handleMenuClick('Orders')}
                                        style={{ backgroundColor: selectedMenu === 'Orders' ? '#e0f7fa' : 'transparent' }}
                                    >
                                        <Stack direction={'row'} spacing={4.5} alignItems={'center'}>
                                            <FaWallet style={{ color: '#1976d2', width: '27px', height: '27px' }} />
                                            <Typography sx={{ color: 'grey !important', fontSize: '16px !important' }}>MY ORDERS</Typography>
                                        </Stack>
                                        <IoIosArrowForward style={{ width: '27px', height: '27px', color: 'grey ' }} />
                                    </Stack>
                                    <Divider sx={{ mb: 1 }} />





                                    <Stack direction={'row'} spacing={3} alignItems={'center'} sx={{ cursor: 'pointer', p: 1.4 }}

                                        style={{ backgroundColor: selectedMenu === 'Account Settings' ? '#e0f7fa' : 'transparent' }}
                                    >
                                        <MdAdminPanelSettings style={{ color: '#1976d2', width: '35px', height: '35px' }} />
                                        <Typography sx={{ color: 'grey !important', fontSize: '16px !important' }}>ACCOUNT SETTINGS</Typography>
                                    </Stack>

                                    <Stack spacing={1} sx={{ pl: 8, mt: 1, mb: 1, pl: 1 }} >
                                        <Typography variant='body2'

                                            sx={{
                                                color: 'black !important', fontSize: '14px !important', pl: 1, py: 1,
                                                cursor: 'pointer',
                                                backgroundColor: selectedMenu === 'Personal Information' ? '#e0f7fa' : 'transparent'

                                            }}
                                            onClick={() => handleMenuClick('Personal Information')}
                                            style={{}}
                                        >
                                            Personal Information
                                        </Typography>
                                        <Typography variant='body2'
                                            sx={{
                                                color: 'black !important', fontSize: '14px !important', pl: 1, py: 1,
                                                cursor: 'pointer',
                                                backgroundColor: selectedMenu === 'Parents Details' ? '#e0f7fa' : 'transparent'

                                            }}
                                            onClick={() => handleMenuClick('Parents Details')}

                                        >
                                            Parents Details
                                        </Typography>
                                        {/* <Typography variant='body2'
                                            sx={{
                                                color: 'black !important', fontSize: '14px !important', pl: 1, py: 1,
                                                cursor: 'pointer',
                                                backgroundColor: selectedMenu === 'Documents' ? '#e0f7fa' : 'transparent'

                                            }}
                                            onClick={() => handleMenuClick('Documents')}

                                        >
                                            Documents
                                        </Typography> */}
                                    </Stack>

                                </MainCard>

                                <MainCard sx={{ mt: 2, p: 2 }} content={false}>
                                    <Stack direction={'row'} spacing={2} alignItems={'center'} sx={{ cursor: 'pointer' }} onClick={() => handleLogout()}>
                                        <IoIosLogOut style={{ color: '#1976d2', width: '35px', height: '35px' }} />
                                        <Typography sx={{ fontSize: '15px !important' }}>Logout</Typography>
                                    </Stack>
                                </MainCard>
                            </Grid>

                            <Grid item xs={12} md={9}>
                                <MainCard sx={{ mt: 2, pb: '30px' }} content={false}>
                                    <Typography sx={{ p: 2 }}>{selectedMenu}</Typography>
                                    <Divider />
                                    {selectedMenu === 'Personal Information' && (
                                        <Grid container justifyContent={'center'} alignItems={'center'} spacing={3} sx={{ p: 2 }}>
                                            <Grid item xs={12} md={4}>
                                                <Stack spacing={0.5} >
                                                    <Typography color={'secondary'} sx={{ color: 'grey !important', fontSize: '14px !important' }}>
                                                        Name
                                                    </Typography>
                                                    <Typography>
                                                        {userData?.customername}
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Stack spacing={0.5} >
                                                    <Typography color={'secondary'} sx={{ color: 'grey !important', fontSize: '14px !important' }}>
                                                        Email
                                                    </Typography>
                                                    <Typography>
                                                        {userData?.email}
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Stack spacing={0.5} >
                                                    <Typography color={'secondary'} sx={{ color: 'grey !important', fontSize: '14px !important' }}>
                                                        Phone Number
                                                    </Typography>
                                                    <Typography>
                                                        {userData?.contactno1}
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Stack spacing={0.5} >
                                                    <Typography color={'secondary'} sx={{ color: 'grey !important', fontSize: '14px !important' }}>
                                                        Date of birth
                                                    </Typography>
                                                    <Typography>
                                                        {userData?.dob}
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Stack spacing={0.5} >
                                                    <Typography color={'secondary'} sx={{ color: 'grey !important', fontSize: '14px !important' }}>
                                                        Gender
                                                    </Typography>
                                                    <Typography>
                                                        {userData?.gender}
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Stack spacing={0.5} >
                                                    <Typography color={'secondary'} sx={{ color: 'grey !important', fontSize: '14px !important' }}>
                                                        City
                                                    </Typography>
                                                    <Typography>
                                                        {userData?.city}
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Stack spacing={0.5} >
                                                    <Typography color={'secondary'} sx={{ color: 'grey !important', fontSize: '14px !important' }}>
                                                        State
                                                    </Typography>
                                                    <Typography>
                                                        {userData?.state}
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Stack spacing={0.5} >
                                                    <Typography color={'secondary'} sx={{ color: 'grey !important', fontSize: '14px !important' }}>
                                                        Country
                                                    </Typography>
                                                    <Typography>
                                                        {userData?.country}
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Stack spacing={0.5} >
                                                    <Typography color={'secondary'} sx={{ color: 'grey !important', fontSize: '14px !important' }}>
                                                        Zipcode
                                                    </Typography>
                                                    <Typography>
                                                        {userData?.postcode}
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Stack spacing={0.5} >
                                                    <Typography color={'secondary'} sx={{ color: 'grey !important', fontSize: '14px !important' }}>
                                                        Address
                                                    </Typography>
                                                    <Typography>
                                                        {userData?.address}
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    )}
                                    {selectedMenu === 'Orders' && (
                                        <>
                                            {transaction?.map((item, index) => (
                                                <MainCard key={index} sx={{ m: 2 }} content={false}>
                                                    <Grid container justifyContent="space-around" alignItems="center" sx={{ p: 1 }}>
                                                        <Grid item xs={6} container alignItems="center">
                                                            <Stack direction={'row'} spacing={3} alignItems="center">


                                                                <img
                                                                    alt='Product'
                                                                    src={item?.courses[0]?.image}
                                                                    style={{ width: '80px', height: '80px', objectFit: 'cover' }}
                                                                />
                                                                <Typography sx={{ ml: 2 }}>
                                                                    {item?.courses?.map(product => product?.productname).join(', ')}
                                                                </Typography>

                                                            </Stack>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography variant='h5' sx={{ color: 'black !important' }}>
                                                                ${Math.round(item?.totalamount) ?? 'N/A'}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={4} container direction="column" alignItems="flex-end">
                                                            <Typography>Purchased on</Typography>
                                                            <Typography variant='caption'>
                                                                {item?.orderdate ? moment(item.orderdate).format('DD-MM-YYYY hh:mm A') : 'Date not available'}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </MainCard>
                                            ))}
                                        </>
                                    )}


                                    {selectedMenu === 'Parents Details' && (


                                        !_.isEmpty(userData?.parendata) ?
                                            <Grid container justifyContent={'center'} alignItems={'center'} spacing={3} sx={{ p: 2 }} >
                                                <Grid item xs={12} md={6}>
                                                    <Stack spacing={0.5} >
                                                        <Typography color={'secondary'} sx={{ color: 'grey !important', fontSize: '14px !important' }}>
                                                            Name
                                                        </Typography>

                                                        <Typography>
                                                            {userData?.parendata?.parentname}
                                                        </Typography>
                                                    </Stack>
                                                </Grid>






                                                <Grid item xs={12} md={6}>
                                                    <Stack spacing={0.5}>
                                                        <Typography color={'secondary'} sx={{ color: 'grey !important', fontSize: '14px !important' }}>
                                                            Email
                                                        </Typography>
                                                        <Typography>
                                                            {userData?.parendata?.authname}
                                                        </Typography>

                                                    </Stack>
                                                </Grid>

                                                <Divider />

                                                <Grid item xs={12} md={6}>
                                                    <Stack spacing={0.5}>
                                                        <Typography color={'secondary'} sx={{ color: 'grey !important', fontSize: '14px !important' }} >
                                                            Mobile No
                                                        </Typography>

                                                        <Typography>
                                                            {userData?.parendata?.contactno}
                                                        </Typography>
                                                    </Stack>
                                                </Grid>

                                                <Grid item xs={12} md={6}>
                                                    <Stack spacing={0.5}>
                                                        <Typography color={'secondary'} sx={{ color: 'grey !important', fontSize: '14px !important' }} >
                                                            Relation
                                                        </Typography>

                                                        <Typography>
                                                            {userData?.parendata?.parentrelation}
                                                        </Typography>
                                                    </Stack>
                                                </Grid>


                                            </Grid>

                                            :
                                            <Typography>No Information provided ...</Typography>




                                    )}
                                    {/* {selectedMenu === 'Documents' && (
                                        <Typography sx={{ p: 2 }}>Documents content here...</Typography>
                                    )} */}
                                </MainCard>
                            </Grid>
                        </Grid>




                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}

export default MyProfile;
