import React, { useEffect, useState } from 'react'
import { Box, CardMedia, Container, Grid, Typography, Button, colors } from '@mui/material'

import tick from "../../assets/images/driving-lesson/tick.png"
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import prgrm1 from "../../assets/images/home/prgrm1.png";
import RlthousantBg from "../../assets/images/roadTesting/RlthousantBg.png";
import { base_Url } from '../BaseUrl/ApiUrl';
import axios from 'axios';
import Register from '../Register/Register';
import GreenBanner from '../BaseUrl/GreenBanner';

import blueBox from '../../assets/images/home/footban2.jpg';
import { useNavigate } from 'react-router-dom';

const styles = {

    buttonWhite: {
        background: '#fff',
        color: '#5B5B5B',
        textTransform: 'capitalize',
        padding: '10px 20px',
        '&:hover': {
            background: 'var(--primary) ',
            color: '#fff',
        },
    },

    Prerequisites: {
        width: '20px', height: '20px', margin: '6px 6px 0px 0px',
    },

    PrerequisitesPrimaryText: {
        color: 'var(--primary) !important', fontSize: '18px !important', fontWeight: '500 !important',
    },
    Prerequisites2Box: {
        display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px', mt: '30px',
        '@media (max-width: 600px)': {
            gridTemplateColumns: 'auto',
        }
    },
    Prerequisites3Box: {
        background: 'var(--lightblue)', padding: '40px', mt: '30px', display: 'flex', justifyContent: 'space-between', alignItems: 'center',
        '@media (max-width: 600px)': {
            flexDirection: 'column',
            gap: '30px',
            padding: '40px 20px'
        },
    },
    greenBoxButton: {
        display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '30px 0px 0px ', width: 'unset',
    },
    greenBox: {
        background: `url(${RlthousantBg}) no-repeat top center`, backgroundSize: 'cover', padding: '30px', margin: '30px 0px 0px 30px', borderRadius: '10px',
        '@media (max-width: 600px)': {
            margin: '30px 0px 0px 0px',
        },
    },
    blueBox: {
        marginTop: '30px',
        background: `url(${blueBox}) no-repeat top center`,
        backgroundSize: 'cover', padding: '30px',
        borderRadius: '10px',
    },
    blueBoxText: {
        color: '#fff !important',
        background: '#00000054',
        padding: '10px 10px',
        borderRadius: '10px',
        textAlign: 'center',
        fontWeight: '400 !important',
    }





}

const programsData = [
    {
        prmTop1: "Starting",
        prmTop2: "$124",
        prmImage: prgrm1,
        prmTittle: "Joshua’s Law",
        prmTittle2:
            "30 Hours",
    },

];

const DljlPrerequisites = () => {


    const [roadTesting, setRoadTesting] = useState([])
    const fetchPackages = async () => {
        try {
            const response = await axios.post(`${base_Url}/getAllCourse`, {
                type: ''
            })
            if (response.data.message === "success") {
                console.log('response', response?.data?.response)

                const course5 = response?.data?.response[3]
                setRoadTesting(course5)

            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchPackages()
    }, [])
    console.log('roadTesting', roadTesting)



    const [registerStatus, setRegisterStatus] = useState(false)

    const [finalData, setFinalData] = useState({})

    const fetchcheckPackageisExist = async (packageid) => {
        try {
            const response = await axios.post(`${base_Url}/checkPackageisExist`, {
                packageid: packageid
            })
            if (response.data.message === 'success') {
                console.log('response', response?.data?.response[0])
                setFinalData(response?.data?.response)
                setRegisterStatus(true)
            } else {
                setFinalData({})
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handlePackageNavigate = () => {
        navigate('/schedule-driving-lessons-2', {
            state: { number: 2 }
        })
    }



    console.log('finalData', finalData)
    const navigate = useNavigate()
    return (
        <>
            <Box sx={{ pt: "50px", pb: "40px", }}>
                <Container maxWidth='lg'>

                    <Grid container spacing={2}>
                        <Grid item md={7}>

                            <Grid sx={{ background: 'var(--lightblue)', padding: '40px', '@media (max-width: 900px)': { padding: '40px 20px', } }}>
                                <Typography variant='h4'>Prerequisites</Typography>
                                <Grid sx={{ margin: '30px 0px 0px', display: 'flex', gap: '14px', flexDirection: 'column', }}>
                                    <Grid sx={{ display: 'flex', }}><img src={tick} style={styles.Prerequisites} /> &nbsp; <Typography>
                                        In Georgia, <b></b><span style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }} onClick={() => navigate("/joshua's-law-info")}>Joshua's Law </span> mandates that 16 and 17-year-olds must complete a
                                        30-hour Driver's education course and at least 6 hours of Driving lessons with a certified school, such as 1 ACT Driving School, to obtain their license.
                                    </Typography>
                                    </Grid>
                                    {/* <Grid sx={{ display: 'flex', }}><img src={tick} style={styles.Prerequisites} /> &nbsp; <Typography>A minimum of 6 hours of professional driving instruction from a certified Driver's training school is required to comply with Joshua's Law.</Typography></Grid> */}
                                    <Grid sx={{ display: 'flex', }}><img src={tick} style={styles.Prerequisites} /> &nbsp; <Typography>We provide a <span style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }} onClick={() => navigate('/schedule-driving-lessons-2', {
                                        state: { number: 2 }
                                    })}>package pricing</span> option that enables you to pay BELOW the standard value for both the driving lessons and the course</Typography></Grid>
                                </Grid>
                            </Grid>

                            <Grid sx={styles.Prerequisites2Box}>
                                <Grid sx={{ display: 'flex', }}><img src={tick} style={styles.Prerequisites} /> &nbsp; <Typography variant='h2' sx={styles.PrerequisitesPrimaryText}>Awareness about Georgia’s <br />Driving laws </Typography></Grid>
                                <Grid sx={{ display: 'flex', }}><img src={tick} style={styles.Prerequisites} /> &nbsp; <Typography variant='h2' sx={styles.PrerequisitesPrimaryText}>Understand traffic control</Typography></Grid>
                                <Grid sx={{ display: 'flex', }}><img src={tick} style={styles.Prerequisites} /> &nbsp; <Typography variant='h2' sx={styles.PrerequisitesPrimaryText}>Assess risks while driving</Typography></Grid>
                                <Grid sx={{ display: 'flex', }}><img src={tick} style={styles.Prerequisites} /> &nbsp; <Typography variant='h2' sx={styles.PrerequisitesPrimaryText}>Clear understanding of signs, signals, markings and intersections</Typography></Grid>
                            </Grid>



                            {/* calender */}
                            {/* <Grid sx={styles.Prerequisites3Box}>
                                <Grid>
                                    <Typography variant='h5' sx={{ color: '#000 !important', pb: '10px', }}>Online Class Schedule</Typography>
                                    <Typography variant='h2' sx={{ color: 'var(--primary) !important', fontSize: '16px !important', }}>All Classes 9:30am - 5:30pm</Typography>
                                </Grid>
                                <Grid>
                                    <Button sx={styles.buttonWhite} variant="outlined" startIcon={<WatchLaterIcon />}>
                                        View Calendar
                                    </Button>
                                </Grid>
                            </Grid> */}

                        </Grid>
                        <Grid item md={5}>

                            {/* {programsData.map((program, index) => (
                                <Grid className="startTestingBox" item key={index} xs={11} md={10} sx={{ boxShadow: '1px 1px 6px 2px #0000001f', border: '1px solid #0000001f', marginLeft: 'auto', marginTop: '20px', marginBottom: '60px', }}>
                                    <Grid className="program_box_main startTestingBoxMain" sx={{}}>
                                        <Grid className="prgrm_box_line"></Grid>

                                        <Grid className="prgrm_top_con">
                                            <Typography sx={{ color: "#fff !important" }}>
                                                {program.prmTop1}
                                            </Typography>
                                            <Typography
                                                variant="h3"
                                                sx={{ color: "#fff !important" }}
                                            >
                                                {program.prmTop2}
                                            </Typography>
                                        </Grid>

                                        <Grid className="prgrm_con">

                                            <Grid className="prgrm_txt">
                                                <Grid className="" style={{ padding: "100px 20px 0px" }}>
                                                    <Typography
                                                        component='f20w600'
                                                        sx={{ margin: "0px 0px 16px", }}
                                                    >
                                                        {program.prmTittle}
                                                    </Typography>
                                                    <Typography sx={{ color: 'var(--primary) !important' }}>
                                                        {program.prmTittle2}
                                                    </Typography>
                                                </Grid>

                                                <Grid className="prgrm_but" style={{ marginTop: "30px" }}>
                                                    <a href="#" className="btns btn_1 hvr-bounce-to-right">
                                                        Register
                                                    </a>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))} */}


                            {roadTesting?.courseDetails?.map((program, index) => (
                                <Grid className="startTestingBox" item key={index} xs={11} md={10} sx={{ boxShadow: '1px 1px 6px 2px #0000001f', border: '1px solid #0000001f', marginLeft: 'auto', marginTop: '20px', marginBottom: '60px', }}>
                                    <Grid className="program_box_main startTestingBoxMain" sx={{}}>
                                        <Grid className="prgrm_box_line"></Grid>

                                        <Grid className="prgrm_top_con">
                                            <Typography sx={{ color: "#fff !important" }}>
                                                Starting
                                            </Typography>
                                            <Typography
                                                variant="h3"
                                                sx={{ color: "#fff !important" }}
                                            >
                                                ${program.packageamount}
                                            </Typography>
                                        </Grid>

                                        <Grid className="prgrm_img" style={{ padding: "60px 20px 0px" }} >
                                            <CardMedia
                                                className=""
                                                component="img"
                                                height="200px"
                                                width="100%"
                                                alt="program Image"
                                                src={roadTesting.productimage}
                                                sx={{ objectFit: 'cover' }}
                                            />
                                        </Grid>

                                        {/* <Grid className="prgrm_con"> */}

                                        <Grid className="prgrm_txt">
                                            <Grid className="" style={{ padding: "10px 20px 0px" }}>
                                                <Typography
                                                    variant="h4"
                                                    sx={{
                                                        fontSize: "18px !important",
                                                        margin: "0px 0px 16px",
                                                    }}
                                                >
                                                    {program.packagename}
                                                </Typography>


                                                <Typography sx={{ fontSize: "12px !important" }}>
                                                    {/* {roadTesting.description} */}
                                                    {/* 
                                                    {roadTesting.description.replace('$95.', '')}
                                                    <b style={{fontSize:'15px',textDecoration:'underline'}}>$95</b> */}



                                                    <p>
                                                        30-hour Drivers Education class that covers all aspects of Road awareness and Road safety.
                                                    </p>


                                                </Typography>


                                                <Typography onClick={handlePackageNavigate} sx={{ marginTop: '20px', fontSize: '18px !important', color: '#1976d2 !important', textDecoration: 'underline', cursor: 'pointer' }}>
                                                    *Click here to add Driving Lessons and get Drivers Education at{' '}
                                                    <b style={{ fontSize: '21px', textDecoration: 'underline' }}>$95</b>.
                                                </Typography>
                                            </Grid>

                                            <Grid onClick={() => fetchcheckPackageisExist([program.packageid])} className="prgrm_but" style={{ marginTop: "30px" }}>
                                                <a className="btns btn_1 hvr-bounce-to-right">
                                                    GET NOW
                                                </a>
                                            </Grid>

                                        </Grid>
                                        {/* </Grid> */}
                                    </Grid>
                                </Grid>
                            ))}


                            <Register
                                openStatus={registerStatus}
                                setRegisterStatus={setRegisterStatus}
                                finalData={finalData}
                                classmode={'1'}
                            />

                        </Grid>

                    </Grid>




                </Container>
            </Box>

        </>
    )
}

export default DljlPrerequisites
