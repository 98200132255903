import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from '../pages/Home';
import DrivingLesson from '../pages/Drivinglessons';
import RoadTesting from '../pages/RoadTesting';
import DefensiveDrivingLocation from '../pages/DefensiveDrivingLocation';
import ClinicalEvaluation from '../pages/ClinicalEvaluation';
import DuiRiskReductionLocation from '../pages/DuiRiskReductionLocation';
import Locations from '../pages/Locations';
import DrivingLessonJoshuaLaw from '../pages/DrivingLessonJoshuaLaw';
import DrivingLessonOnline from '../pages/DrivingLessonOnline';
import DefensiveDrivingRemote from '../pages/DefensiveDrivingRemote';
import DuiRiskReductionZoom from '../pages/DuiRiskReductionZoom';
import ExamplePage from '../pages/ExamplePage';
import Login from '../pages/Login';
import Dashboard from '../pages/DashBoard/Dashboard';
import SuccessPage from '../pages/Register/SuccessPage';
import CancellationPolicy from '../pages/CancellationPolicy';
import GDECScholarship from '../pages/GDECScholarship';
import JoshuaLaw from '../pages/JoshuaLaw';
import VictimImpact from '../pages/VictimImpact';
import MyCart from '../pages/AddtoCart/MyCart';
import MyProfile from '../pages/Profile/MyProfile';
import InsurenceIndex from '../pages/InsurenceReduction/Index';

// Scroll to top component

const ScrollToTop = () => {

  useEffect(() => {
    const onRouteChange = () => {
      window.scrollTo(0, 0);
    };

    window.addEventListener('popstate', onRouteChange);

    return () => {
      window.removeEventListener('popstate', onRouteChange);
    };
  }, []);

  return null;
};

const MainRoutes = () => {
  return (
    <Router>

      <Routes>

        <Route path="/" element={<Home />} />
        <Route path="/schedule-driving-lessons-2" element={<DrivingLesson />} />
        <Route path="/drivers-education" element={<DrivingLessonJoshuaLaw />} />
        <Route path="/online-drivers-education" element={<DrivingLessonOnline />} />
        <Route path="/road-test-services" element={<RoadTesting />} />
        <Route path="/defensive-driving-at-location" element={<DefensiveDrivingLocation />} />
        <Route path="/defensive-driving-course-remote" element={<DefensiveDrivingRemote />} />
        <Route path="/dui-risk-reduction-at-location" element={<DuiRiskReductionLocation />} />
        <Route path="/dui-risk-reduction-course-remote" element={<DuiRiskReductionZoom />} />
        <Route path="/clinical-evaluation" element={<ClinicalEvaluation />} />
        <Route path="/our-locations" element={<Locations />} />
        <Route path="/privacy-policy-cancellation-policy" element={<CancellationPolicy />} />
        <Route path="/login" element={<Login />} />
        <Route path="/example" element={<ExamplePage />} />
        <Route path="/checkout" element={<SuccessPage />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/gdec-scholarship" element={<GDECScholarship />} />
        <Route path="/joshua's-law-info" element={<JoshuaLaw />} />
        <Route path="/victim-impact-panel" element={<VictimImpact />} />
        <Route path="/cart" element={<MyCart />} />
        
        <Route path="/myprofile" element={<MyProfile />} />

        <Route path="/insurance-reduction" element={<InsurenceIndex/>} />
      </Routes>
    </Router>
  );
};

export default MainRoutes;
