import React from "react";
import { Box, CardMedia, Container, Grid, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import faqlft from "../../assets/images/home/faq-lft.png";


const styles = {

  faqRight: {

    '@media (max-width: 900px)': {
      margin: 'auto',

    },
  },
  faqRightImage: {
    width: '80%', marginRight: 'auto', marginY: '30px',
    '@media (max-width: 900px)': {
      width: '100%'
    }
  }
}

const homeFaq = [
  {
    question: 'What services does 1 Act Driving Schools offer?',
    answer: 'We offer various state certified services here at 1 ACT Driving Schools. We offer 20-Hour DUI/Risk Reduction Courses, 6-Hour Driver Improvement (Defensive Driving) Courses, 30-Hour Driver’s Education Courses, Behind the Wheel Lessons, and On-Site Road Testing.',
  },
  {
    question: 'Are your instructors certified?',
    answer: 'Yes, ALL instructors by law must be certified by GA Department of Driver Services to teach at a certified driving school',
  },
  {
    question: "Are the courses approved by the Georgia Department of Driver Services?",
    answer: 'Yes, we ONLY offer courses and services that are approved by GA Department of Driver Services and all court officials. You may visit GA DDS website and search for our name under certified and approved schools and you’ll see us there.',
  },
  {
    question: "Are there any additional fees that I need to pay?",
    answer: 'No, there aren’t any additional fees. However, if you cancel/reschedule a service or course, then please visit our policy to see if any fees apply to you',
  },
  {
    question: "What are the locations of 1Act Driving Schools in the Atlanta area?",
    answer: '1Act Driving Schools have four locations in the Atlanta area Sandy Springs, Marietta, Lawrenceville, Doraville and Alpharetta, making it easy for you to access their services.',
  },
  {
    question: "Are the certificates for the DDS-Certified Live Remote and in-person classes the same from the Georgia Department of Driver Services?",
    answer: 'Yes, the certificates for both the DDS-Certified Live Remote and in-person classes are exactly the same and are issued by the Georgia Department of Driver Services.',
  },
  // {
  //   question: "Are there any additional fees that I need to pay?",
  //   answer: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. uspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.',
  // },
]


const HomeFaq = () => {

  return (
    <>
      <Box sx={{ pt: "50px", pb: '100px', position: "relative" }}>
        <div className="homefaq_ani"></div>
        <Container>
          <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center', }}>

            <Grid item md={6} sx={styles.faqRight}>
              <Typography variant="h4" sx={{ pt: 2, pb: 2 }}>
                Frequently Asked Question
              </Typography>
              <Grid data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                <CardMedia className="hmeslidelft" component="img" alt="faq Image" src={faqlft} sx={styles.faqRightImage} />
              </Grid>
            </Grid>

            <Grid item md={6}>
              <div>

                {homeFaq.map((homeFaq, index) => (

                  <Accordion key={index} defaultExpanded={index === 0}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header">

                      <Typography variant="h6">
                        {homeFaq.question}
                      </Typography>

                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography sx={{ fontSize: '15px !important', }} dangerouslySetInnerHTML={{ __html: homeFaq.answer.replace(/<br\s*\/?>/mg, "<br/>", "'", ".", "") }}></Typography>
                    </AccordionDetails>
                  </Accordion>
                ))}

              </div>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default HomeFaq;
