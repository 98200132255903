import React, { useEffect, useState } from 'react';
import { Stack, Button, Grid, TextField, InputAdornment, InputLabel, Typography, Autocomplete, CircularProgress, Checkbox, Box } from '@mui/material';
import { CheckOutlined } from '@ant-design/icons';
import cvv from '../../assets/cvv.png';
import master from '../../assets/master-card.png';
import MainCard from '../../components/MainCard';
import { Toaster, toast } from "react-hot-toast";
import visa from '../../assets/checkout/purepng.com-visa-logologobrand-logoiconslogos-251519938794uqvcz.png'
import visa2 from '../../assets/checkout/American-Express-Color.png'
import visa3 from '../../assets/checkout/Apple_Pay-Logo.wine.png'
import visa4 from '../../assets/checkout/Discover-logo.png'
import visa5 from '../../assets/checkout/Mastercard-Logo-2016-2020.png'
import visa6 from '../../assets/checkout/jcb_card_payment-512.webp'
import visa7 from '../../assets/checkout/paypal-icon-2048x1286-ha8kzb8k.png'
import visa8 from '../../assets/checkout/2166341.webp'
import CheckIcon from '@mui/icons-material/Check';
import ToggleButton from '@mui/material/ToggleButton';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import TermsAndCondition from './TermsAndCondition';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


const PaymentPage = ({ onClickSubmit, setActiveStep, coursedetails, paymentDetails, setPaymentDetails, formData, setFormData, selected, setSelected }) => {


    const failure = (data) => {
        toast.error(data, {
            position: 'top-center',
            style: { border: '1px solid red' },
            duration: 3000,
            cancel: {
                label: "x",
                // style: {
                //     backgroundColor: '#a8261d', 
                //     color: '#ffffff', 
                //     border: 'none', 
                //     padding: '2px 8px',
                //     borderRadius: '4px', 
                // },
                onClick: () => console.log('Cancel!'),
            },

        });
    }


    const [errors, setErrors] = useState({});

    const validateCardNumber = (number) => {
        const regex = /^\d{15,16}$/;
        return regex.test(number);
    };

    const validateExpiryDate = (month, year) => {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1;
        const currentYear = currentDate.getFullYear() % 100;

        if (!month || !year) return false;
        if (month < 1 || month > 12) return false;
        if (year < currentYear || (year === currentYear && month < currentMonth)) return false;
        return true;
    };

    const validateCVV = (cvv) => {
        const regex = /^\d{3,4}$/; // CVV should be 3 or 4 digits
        return regex.test(cvv);
    };



    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Validation logic for individual fields
        if (name === 'cvvNumber') {
            if (value.length > 4) {
                return;
            }
        }
        if (name === 'expiryMonth' || name === 'expiryYear') {
            if (value.length > 2) {
                return;
            }
        }

        if (name === 'cardNumber') {
            if (value.length > 19) {
                return;
            }
        }

        setPaymentDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const [check, setCheck] = useState(false)

    const handleSubmit = () => {
        const { cardNumber, expiryMonth, expiryYear, cvvNumber } = paymentDetails;
        const newErrors = {};

        // Validate each field and collect errors
        if (!validateCardNumber(cardNumber)) {
            newErrors.cardNumber = 'Invalid card number. It must be 16 digits.';
            failure('Invalid card number.Please check and try again.');
            return;
        }
        if (!validateExpiryDate(expiryMonth, expiryYear)) {
            newErrors.expiryDate = 'Invalid expiry date. Please check and try again.';
            failure('Invalid expiry date. Please check and try again.');
            return;
        }
        if (!validateCVV(cvvNumber)) {
            newErrors.cvvNumber = 'Invalid CVV. It must be 3 or 4 digits.';
            failure('Invalid CVV.Please check and try again.');
            return;
        }

        setErrors(newErrors);

        if (check == false) {
            return toast.error('please check terms and conditions to proceed')
        }

        // if (Object.keys(newErrors).length === 0) {
        onClickSubmit();
        // }
    };

    const roadTest = coursedetails?.some(course => course.type === 5);
    const handle = () => {
        if (roadTest) {
            setActiveStep(1);
        } else {
            setActiveStep(0);
        }
    };

    const getImage = () => {
        return <img src={master} alt="card" />;
    };

    const navigate = useNavigate()




    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleChange2 = (e) => {
        const { name, value } = e.target;
        setPaymentDetails({
            ...paymentDetails,
            [name]: value,
        });
    };

    // terms and condition in popup
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    // terms and condition in popup


    // city , state , country
    const [countryInfo, setCountryInfo] = useState([]);
    const [countryLoading, setCountryLoading] = useState(true)
    const fetchCountryInfo = async () => {
        try {
            const response = await axios.get(`https://countriesnow.space/api/v0.1/countries/iso`);
            const data = response?.data?.data;
            const result = data.map((item) => ({
                id: item.Iso2,
                label: item.name
            }));

            setCountryInfo(result);
            setCountryLoading(false)
        } catch (error) {
            console.error('Error fetching states:', error);
        }
    };


    const [stateInfo, setStateInfo] = useState([]);
    const [stateLoading, setStateLoading] = useState(false)
    const fetchState = async (id) => {

        try {
            setStateInfo([])
            const response = await axios.post(`https://countriesnow.space/api/v0.1/countries/states`, {
                "country": id
            }
            );
            const data = response?.data?.data?.states;
            const result = data.map((item) => ({
                id: item.state_code,
                label: item.name
            }));
            setStateLoading(false)
            setStateInfo(result);

        } catch (error) {
            console.error('Error fetching states:', error);
        }
    };



    const [cityInfo, setCityInfo] = useState([]);
    const [cityLoading, setCityLoading] = useState(false)

    const fetchCity = async (selectedStateId, country) => {

        try {
            setCityInfo([])
            const response = await axios.post(`https://countriesnow.space/api/v0.1/countries/state/cities`, {
                "country": `${country}`,
                "state": `${selectedStateId}`
            });
            const data = response.data.data;
            const result = data?.map((item) => ({
                id: item,
                label: item
            }));
            setCityInfo(result);
            setCityLoading(false)
        } catch (error) {
            console.error('Error fetching cities:', error);
        }
    };

    useEffect(() => {
        fetchCountryInfo()
        setFormData({
            ...formData,
            ['newstate']: 'Georgia',
        });
    }, []);


    useEffect(() => {


        if (formData?.newcountry) {
            fetchState(formData?.newcountry)
        }

        if (formData?.newcountry && formData?.newstate) {
            fetchCity(formData?.newstate, formData?.newcountry)
        }
    }, [formData?.newcountry]);

    // city , state , country

    return (
        <div>

            <Toaster
            // toastOptions={{
            //     duration: 3000,
            //     style: {
            //         cursor: 'pointer',
            //     },
            //     // Clickable setting ensures that clicking the toast will close it
            //     ariaLive: 'assertive',
            //     position: 'top-center',
            // }}
            />
            <Box  >
                <Grid container spacing={2} alignItems={'center'}>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item md={5}>
                                <Stack direction={{ sm: 'column', md: 'column' }}>
                                    <InputLabel>Card Number :</InputLabel>
                                    <Typography color="caption" sx={{ display: { xs: 'none', sm: 'flex' }, fontSize: '12px !important', color: 'gray !important', fontWeight: '100 !important' }}>
                                        Enter the 16 digit card number on the card
                                    </Typography>
                                    {/* {errors.cardNumber && <Typography color="error">{errors.cardNumber}</Typography>} */}
                                </Stack>
                            </Grid>
                            <Grid item md={7} sx={{ padding: '0px !important', margin: '0px !important' }}>
                                <TextField
                                    size="small"
                                    fullWidth
                                    type="number"
                                    name="cardNumber"
                                    value={paymentDetails?.cardNumber}
                                    onChange={(e) => {
                                        const value = e?.target?.value;
                                        if (/^\d{0,16}$/.test(value)) {
                                            setPaymentDetails((prev) => ({
                                                ...prev,
                                                cardNumber: value,
                                            }));
                                        }
                                    }}
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item md={5}>
                                <Stack>
                                    <InputLabel>Expiry Date :</InputLabel>
                                    <Typography color="caption" sx={{ display: { xs: 'none', sm: 'flex' }, fontSize: '12px !important', color: 'gray !important', fontWeight: '100 !important' }}>
                                        Enter the expiration on the card
                                    </Typography>
                                    {/* {errors.expiryDate && <Typography color="error">{errors.expiryDate}</Typography>} */}
                                </Stack>
                            </Grid>
                            <Grid item md={7}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Stack direction="row" spacing={2} alignItems="center">
                                            <TextField
                                                type="number"
                                                fullWidth
                                                placeholder="MM"
                                                size="small"
                                                name="expiryMonth"
                                                value={paymentDetails?.expiryMonth}
                                                onChange={(e) => {
                                                    const value = e?.target?.value;
                                                    if (/^\d{0,2}$/.test(value)) {
                                                        setPaymentDetails((prev) => ({
                                                            ...prev,
                                                            expiryMonth: value,
                                                        }));
                                                    }
                                                }}
                                            />

                                            <InputLabel>/</InputLabel>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            type="number"
                                            fullWidth
                                            placeholder="YY"
                                            size="small"
                                            name="expiryYear"
                                            value={paymentDetails?.expiryYear}
                                            onChange={(e) => {
                                                const value = e?.target?.value;
                                                if (/^\d{0,2}$/.test(value)) {
                                                    setPaymentDetails((prev) => ({
                                                        ...prev,
                                                        expiryYear: value,
                                                    }));
                                                }
                                            }}
                                        />

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item md={5}>
                                <Stack>
                                    <InputLabel>CVV Number :</InputLabel>
                                    <Typography color="caption" sx={{ display: { xs: 'none', sm: 'flex' }, fontSize: '12px !important', color: 'gray !important', fontWeight: '100 !important' }}>
                                        Enter the 3 or 4 digit number on the card
                                    </Typography>
                                    {/* {errors.cvvNumber && <Typography color="error">{errors.cvvNumber}</Typography>} */}
                                </Stack>

                            </Grid>
                            <Grid item md={7} >
                                {/* <TextField
                                    type="password"
                                    size='small'
                                    fullWidth
                                    name="cvvNumber"
                                    value={paymentDetails.cvvNumber}
                                    // onChange={handleInputChange}
                                    onChange={(e) => {
                                        setPaymentDetails((prev) => {
                                            return {
                                                ...prev,
                                                cvvNumber: e?.target?.value
                                            }
                                        })
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <img src={cvv} alt="CVV" />
                                            </InputAdornment>
                                        )
                                    }}
                                /> */}
                                <TextField
                                    type="password"
                                    size="small"
                                    fullWidth
                                    name="cvvNumber"
                                    value={paymentDetails.cvvNumber}
                                    onChange={(e) => {
                                        const value = e?.target?.value;
                                        if (/^\d{0,4}$/.test(value)) {
                                            setPaymentDetails((prev) => ({
                                                ...prev,
                                                cvvNumber: value,
                                            }));
                                        }
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <img src={cvv} alt="CVV" />
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item md={6}>
                                <TextField
                                    type="text"
                                    fullWidth
                                    placeholder="Cardholder First Name"
                                    size='small'
                                    name="cardholderfirstname"
                                    value={paymentDetails?.cardholderfirstname}
                                    // onChange={handleInputChange}
                                    onChange={(e) => {
                                        setPaymentDetails((prev) => {
                                            return {
                                                ...prev,
                                                cardholderfirstname: e?.target?.value
                                            }
                                        })
                                    }}
                                />

                            </Grid>
                            <Grid item md={6} >
                                <TextField
                                    type="text"
                                    fullWidth
                                    placeholder="Cardholder Last Name"
                                    size='small'
                                    name="cardholderlastname"
                                    value={paymentDetails?.cardholderlastname}
                                    // onChange={handleInputChange}
                                    onChange={(e) => {
                                        setPaymentDetails((prev) => {
                                            return {
                                                ...prev,
                                                cardholderlastname: e?.target?.value
                                            }
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item md={6}>
                                <TextField
                                    required
                                    size="small"
                                    type="text"
                                    name="cardzipcode"
                                    value={paymentDetails?.cardzipcode}
                                    onChange={handleChange2}
                                    // label='Zip code'
                                    placeholder='Zip code'
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />

                            </Grid>
                            <Grid item md={6} >

                                <Stack  direction={'row'} alignItems={'center'} spacing={1}>

                                    <Typography color="caption" variant='caption' sx={{ color: 'gray !important',width:'fit-content',whiteSpace: 'nowrap' , fontWeight:'bold' }} >Accepted Cards</Typography>
                                    <img src={visa} alt={'visa-image'} width={50} height={20} />
                                    <img src={visa2} alt={'visa-image'} width={60} height={30} />
                                    {/* <img src={visa3} alt={'visa-image'} width={60} height={30} /> */}
                                    <img src={visa4} alt={'visa-image'} width={60} height={40} />
                                    <img src={visa5} alt={'visa-image'} width={50} height={25} />
                                    {/* <img src={visa6} alt={'visa-image'} width={70} height={40} /> */}
                                    {/* <img src={visa7} alt={'visa-image'} width={50} height={30} /> */}
                                    {/* <img src={visa8} alt={'visa-image'} width={60} height={40} /> */}

                                </Stack>

                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>






            </Box>

            <Box>


                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    {/* <ToggleButton
                        value="check"
                        size='small'
                        selected={selected}
                        onChange={() => {
                            setSelected(!selected);
                        }}
                    >
                        <CheckIcon />
                    </ToggleButton> */}
                    <Checkbox {...label} onClick={() => setSelected(!selected)} />
                    <Typography color="caption" sx={{ color: 'gray !important' }}>Billing Address : Same as Purchase Address</Typography>
                </Stack>


                {/* card holder address info */}

                <Grid container spacing={2} sx={{ p: 2, }}>

                    <Grid item xs={12} md={12}>
                        <Stack>

                            <TextField
                                required
                                fullWidth
                                size="small"
                                type="text"
                                name="billingaddressz"
                                value={selected ? formData?.billingaddress : paymentDetails?.billingaddressz}
                                onChange={selected ? handleChange : handleChange2}
                                // label=' Address'
                                placeholder=' Address'
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Stack>
                    </Grid>


                    <Grid item xs={12} md={6}>
                        <Autocomplete
                            id="cityInfo"
                            options={cityInfo ? cityInfo : []}
                            loading={cityLoading}
                            getOptionLabel={(option) => option.label}
                            value={selected ? cityInfo?.find((option) => option?.label == formData?.newcity) || null : cityInfo?.find((option) => option?.label == paymentDetails?.billingcityz) || null}
                            onChange={(event, value) => {
                                if (selected) {
                                    if (value) {
                                        setFormData({
                                            ...formData,
                                            ['newcity']: value?.label,
                                        });
                                    } else {
                                        setFormData((prevFormData) => ({
                                            ...prevFormData,
                                            newcity: '',
                                        }));
                                    }
                                }
                                else {
                                    if (value) {
                                        setPaymentDetails({
                                            ...paymentDetails,
                                            ['billingcityz']: value?.label,
                                        });
                                    } else {
                                        setPaymentDetails((prevFormData) => ({
                                            ...prevFormData,
                                            billingcityz: '',
                                        }));
                                    }
                                }
                            }}
                            renderInput={(params) => (
                                <TextField {...params} placeholder="City name" label='City' size='small'
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {cityLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}

                                />
                            )}
                            autoHighlight={true}
                        />
                    </Grid>


                    <Grid item xs={12} md={6}>

                        <Autocomplete
                            id="state-input"
                            loading={stateLoading}
                            options={stateInfo || []}
                            value={selected ? stateInfo?.find((option) => option?.label === formData?.newstate) || null : stateInfo?.find((option) => option?.label === paymentDetails?.billingstatez) || null}
                            onChange={(event, value) => {
                                if (selected) {
                                    if (value) {
                                        setCityLoading(true)
                                        setFormData((prevFormData) => ({
                                            ...prevFormData,
                                            newstate: value?.label,
                                            newcity: '', // Clear the city when state changes
                                        }));
                                        fetchCity(value?.label, formData?.newcountry);
                                    } else {

                                        setFormData((prevFormData) => ({
                                            ...prevFormData,
                                            newstate: '',
                                            newcity: '', // Clear the city when state is cleared
                                        }));
                                    }
                                }
                                else {
                                    if (value) {
                                        setCityLoading(true)
                                        setPaymentDetails((prevFormData) => ({
                                            ...prevFormData,
                                            billingstatez: value?.label,
                                            billingcityz: '', // Clear the city when state changes
                                        }));
                                        fetchCity(value?.label, paymentDetails?.billingcountryz);
                                    } else {
                                        setPaymentDetails((prevFormData) => ({
                                            ...prevFormData,
                                            billingstatez: '',
                                            billingcityz: '', // Clear the city when state is cleared
                                        }));
                                    }
                                }
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="State" size="small"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {stateLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                            autoHighlight
                        />

                    </Grid>

                    <Grid item xs={12} md={6}>

                        <Autocomplete
                            id="country-input"
                            loading={countryLoading}
                            options={countryInfo || []}
                            value={selected ? countryInfo?.find((option) => option?.label === formData?.newcountry) || null : countryInfo?.find((option) => option?.label === paymentDetails?.billingcountryz) || null}
                            onChange={(event, value) => {
                                if (selected) {
                                    if (value) {
                                        setStateLoading(true)
                                        // setCityLoading(true)
                                        setFormData((prevFormData) => ({
                                            ...prevFormData,
                                            newcountry: value?.label,
                                            newcity: '',
                                            newstate: '',
                                        }));
                                        fetchState(value?.label);
                                    } else {
                                        // setStateLoading(true)
                                        setFormData((prevFormData) => ({
                                            ...prevFormData,
                                            newcountry: '',
                                            newstate: '',
                                            newcity: '',
                                        }));
                                    }
                                }
                                else {
                                    if (value) {
                                        setStateLoading(true)
                                        // setCityLoading(true)
                                        setPaymentDetails((prevFormData) => ({
                                            ...prevFormData,
                                            billingcountryz: value?.label,
                                            billingcityz: '',
                                            billingstatez: '',
                                        }));
                                        fetchState(value?.label);
                                    } else {
                                        // setStateLoading(true)
                                        setPaymentDetails((prevFormData) => ({
                                            ...prevFormData,
                                            billingcountryz: '',
                                            billingstatez: '',
                                            billingcityz: '',
                                        }));
                                    }
                                }
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="Country" size="small"

                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {countryLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                            autoHighlight
                        />

                    </Grid>


                    <Grid item xs={12} sm={6}>
                        <Stack>

                            <TextField
                                required
                                size="small"
                                type="text"
                                name="billingzipcode"
                                value={selected ? formData.zipcode : paymentDetails?.billingzipcode}
                                onChange={selected ? handleChange : handleChange2}
                                // label='Zip code'
                                placeholder='Zip code'
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Stack>
                    </Grid>

                </Grid>

                {/* card holder address info */}


            </Box>

            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <Checkbox {...label} onClick={() => setCheck(!check)} />

                <Typography>I Have read and  agree to the Cancellation Policy in
                    <Typography sx={{ display: 'inline-block', color: 'blue !important', cursor: 'pointer', textDecoration: 'underline', padding: '0 0 0 5px' }} onClick={() => setOpen(true)}>terms and conditions</Typography>

                </Typography>
                <TermsAndCondition open={open} setOpen={setOpen} />
            </Stack>


            <Stack justifyContent="space-between" direction="row" sx={{ mt: 3 }}>
                <Button variant="contained" onClick={handle}>Back</Button>
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    Submit
                </Button>
            </Stack>
        </div>
    );
}

export default PaymentPage;
