import React from 'react'
import { Drawer, useMediaQuery, Stack, Typography, IconButton, Divider, Grid, Button } from '@mui/material'
import { IoMdCloseCircleOutline } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
const QuestionDrawer = ({ open, setOpen, fetchcheckPackageisExist, packageid,route,coursename , navigateto }) => {


    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const navigate = useNavigate()
    return (
        <div>

            <Drawer anchor="right" open={open} onClose={() => setOpen(false)}

                ModalProps={{ BackdropProps: { invisible: true } }}
                PaperProps={{ sx: { width: isSmallScreen ? '100%' : '37%' } }}
            >
                {/* <Stack direction='row' justifyContent='space-between' sx={{ p: 1 }} alignItems={'center'}>
                    <Typography variant="h5" sx={{ color: 'black !important' }}> Checkout</Typography>
                    <IconButton sx={{ fontSize: '30px', color: 'red' }} variant="outlined" color='error' onClick={() => setOpen(false)}  >
                        <IoMdCloseCircleOutline sx={{ fontSize: '30px' }} />
                    </IconButton>
                </Stack> */}
                {/* <Divider /> */}
                <div style={{ margin: '20px 60px 0px  60px' }}>


                    <img
                        src='https://webnox.blr1.digitaloceanspaces.com/studentportal/Asset%203bdges%201.png'
                        alt='know'
                        style={{ width: '300px', height: '300px', objectFit: 'contain' }}

                    />

                    <Typography sx={{ fontSize: '18px !important', fontWeight: 500 }}>DDS - Certified live remote or In person classess - </Typography>
                    <Typography sx={{ fontSize: '18px !important', fontWeight: 500, color: '#1976d2 !important' }}>Certificates are extactly the same from GA.dept. of driver services.</Typography>

                    <Divider sx={{ mt: 1, mb: 6 }} />

                    <Typography>Switch to {coursename}</Typography>


                    <Grid className="prgrm_but" sx={{ my: "30px" }} onClick={() => {
                        if(navigateto){
                            return navigateto()
                        }
                        navigate(route)}}>
                        <a className="btns btn_1 hvr-bounce-to-right" >
                            Register
                        </a>
                    </Grid>

                    <Button variant='outlined' onClick={() => fetchcheckPackageisExist(packageid)} size='large' sx={{ px: 2 }}>
                        Skip
                    </Button>
                </div>

            </Drawer>
        </div>
    )
}

export default QuestionDrawer