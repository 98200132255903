

import React, { useMemo } from 'react';
import { Slide, IconButton, DialogTitle, DialogContent, Typography, Stack, DialogActions, Checkbox, Button, FormControlLabel, Dialog, Box } from '@mui/material';

import { IoMdCloseCircleOutline } from "react-icons/io";


import { styled } from "@mui/material/styles";
import { useNavigate } from 'react-router-dom';
import { Margin } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="top" ref={ref} {...props} />;
});

const AnimatedDialog = styled(Dialog)({
    "& .MuiPaper-root": {
        animation: `$slideIn 5s`,
        "@keyframes slideIn": {
            "0%": {
                transform: "translateX(-100%)",
            },
            "100%": {
                transform: "translateX(0)",
            },
        },
        padding: '2mm !important',  // Padding before the border in mm
        // margin: '10mm !important',
        // border: '2px solid black !important',
        // borderRadius: '8px',
    },

});



const HomeDialog = ({
    open,
    handleClose, }) => {


    const navigate = useNavigate()
    return (

        <AnimatedDialog
            open={open}
            onClose={handleClose}
            aria-labelledby="custom-dialog-title"
            TransitionComponent={Transition}
            transitionDuration={{ enter: 200, exit: 200 }}
            keepMounted
            fullWidth
            maxWidth={'md'}
        >
            <div style={{border:'2px solid #0063d3',borderRadius:'5px'}}>


                <DialogTitle id="custom-dialog-title">
                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
                        <Typography sx={{ color: 'red !important', textAlign: 'center', flexGrow: 1 }} variant="h5">
                            Limited Time Offer!
                        </Typography>

                        <IconButton sx={{ fontSize: '30px', color: 'black' }} variant="outlined" color='error' onClick={handleClose} >
                            <IoMdCloseCircleOutline sx={{ fontSize: '30px' }} />
                        </IconButton>

                    </Stack>
                </DialogTitle>

                <DialogContent>
                    <Box sx={{ px: 3, py: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography sx={{ color: 'black !important', textAlign: 'center' }}>
                            Receive a Free On-site Road Test with a purchase of <b style={{ textDecoration: 'underline' }}>10 Hours or More </b> Behind the Wheel Driving Lessons
                        </Typography>
                        <br />
                        <Typography sx={{ color: 'blue !important', textAlign: 'center' }}>
                            Driving Lessons Must be purchased between <b style={{ textDecoration: 'underline' }}> "September 12, 2024 through October 13, 2024"</b>
                        </Typography>
                        <br />
                        {/* <Typography sx={{ color: 'black !important', textAlign: 'center' }}>
                            Call 770-274-4223 for Availability
                        </Typography>
                        <br /> */}
                        <Button variant="contained" color="error" onClick={() => navigate("/schedule-driving-lessons-2",{
                            state : {number : 2}
                        })}>BUY NOW</Button>
                    </Box>

                    {/* {dialogContent} */}
                </DialogContent>

                {/* <DialogActions>
                <Button variant="contained" color="error" onClick={handleClose}>
                    Close
                </Button>
            </DialogActions> */}


            </div>
        </AnimatedDialog>
    );
};

export default HomeDialog;
